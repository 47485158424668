import React, { useState } from 'react';
import axios from 'axios';

import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Field, MediaInput, Message } from '@zendeskgarden/react-forms';
import { ReactComponent as SearchIcon } from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';

import { SearchWrapper, SearchButton } from './SearchCustomers.style';
import { Heading3 } from '../../styles/core';
import { isValidEmail } from '../../util/generic';

const CUSTOMER_PREFIX = 'cus_';

const SearchCustomers = ({ setSearchCustomers }) => {
  const [searchInput, setSearchInput] = useState('');
  const [searching, setSearching] = useState(false);
  const [message, setMessage] = useState('');

  const isValid = (query) => isValidEmail(query) || query.startsWith(CUSTOMER_PREFIX) || /^[a-z0-9]{4,}$/i.test(query);

  const handleSearch = async (query) => {
    if (isValid(query)) {
      setMessage('');
      setSearching(true);
      try {
        const { data: customers } = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/customer`, {
          query,
        });
        console.log('customers', customers);
        setSearchCustomers(customers);
      } catch (err) {
        console.error('An error occurred searching for customers', err);
        setMessage('An error occurred searching for customers');
        setSearchCustomers([]);
      }
      setSearching(false);
    }
  };

  const handleKeyDown = ({ key }) => {
    switch (key) {
      case 'Enter':
        handleSearch(searchInput);
        break;
      case 'Escape':
        setSearchInput('');
        break;
      default:
        break;
    }
  };

  return (
    <Grid>
      <Row>
        <Col>
          <Heading3>Lookup Customer</Heading3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Field>
            <SearchWrapper>
              <MediaInput
                placeholder="Enter email or id"
                value={searchInput}
                onChange={({ target: { value } }) => setSearchInput(value)}
                onKeyDown={(e) => handleKeyDown(e)}
                start={<SearchIcon />}
              />
              <SearchButton
                primary
                onClick={() => handleSearch(searchInput)}
                disabled={!isValid(searchInput)}
                loading={searching}
              >
                Search
              </SearchButton>
              <Message>{message}</Message>
            </SearchWrapper>
          </Field>
        </Col>
      </Row>
    </Grid>
  );
};

export default SearchCustomers;
