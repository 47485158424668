import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { ResponsiveLine } from '@nivo/line';
import { Button } from '@zendeskgarden/react-buttons';
import { Spinner, Inline } from '@zendeskgarden/react-loaders';

import {
  TooltipWrapper,
  TooltipSeries,
  TooltipTier,
  SpinnerWrapper,
  WrappedContent as _WrappedContent
} from './index.style';
import { Heading2 } from '../styles/core';
import { formatNumber } from '../util/generic';

export const Tooltip = series => (
  <TooltipWrapper>
    <Heading2 align="center">{series[0].data.xFormatted}</Heading2>
    {series.map(({ serieColor: seriesColor, serieId: tier, data: { yFormatted: agentCount} }, index) => (
      <TooltipSeries key={`series-${index}`}>
        <TooltipTier seriesColor={seriesColor}>{tier}</TooltipTier>
        <span>{agentCount}</span>
      </TooltipSeries>
    ))}
  </TooltipWrapper>
)

export const AreaChart = ({ data, min = 0 }) => (
  <ResponsiveLine
    data={data}
    enableArea={true}
    enableGridX={false}
    curve='monotoneX'
    enableSlices='x'
    areaOpacity={0.8}
    margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
    pointColor={{ theme: 'background' }}
    areaBaselineValue={min}
    sliceTooltip={({ slice: { points } }) => Tooltip(points)}
    xFormat='time:%m/%d/%Y'
    xScale={{ type: 'time', format: '%Y-%m-%d', precision: 'day'}}
    yScale={{ type: 'linear', stacked: true, min: 'auto', max: 'auto' }}
    yFormat={agents => formatNumber(agents)}
    axisBottom={{
      orient: 'bottom',
      format: '%m/%d',
      tickSize: 5,
      tickValues: 'every week',
      tickPadding: 5,
      tickRotation: 0,
      legend: 'WEEK OF',
      legendOffset: 36,
      legendPosition: 'middle',
    }}
    axisLeft={{
      orient: 'left',
      format: formatNumber,
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'AGENTS',
      legendOffset: -50,
      legendPosition: 'middle',
    }}
  />
)

//TODO: added completed checkmark & failed X - temp for 3 seconds
export const LoadingButton = ({ loading, children, disabled, ...renderProps }) => (
  <Button disabled={disabled || loading} {...renderProps}>
    {loading
      ? (
        <Inline />
      )
      : children }
  </Button>
);

export const Authentication = ({
  OAuthProvider,
  loginSuccessCallback,
  loginFailureCallback,
  loginPendingCallback,
  loadedCallback,
}) => (
  <StyledFirebaseAuth
    // uiCallback={ui => ui.disableAutoSignIn()}
    uiConfig={
      OAuthProvider.getOAuthUI(
        loginSuccessCallback,
        loginFailureCallback,
        loginPendingCallback,
        loadedCallback,
      )
    }
    firebaseAuth={OAuthProvider.getOAuth()}
  />
);

export const LoadingSpinner = () => (
  <SpinnerWrapper>
    <Spinner size="25vh" />
  </SpinnerWrapper>
);

export const WrappedContent = _WrappedContent
