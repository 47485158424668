import React, { useState } from 'react';
import { Sidebar, Main } from '@zendeskgarden/react-chrome';

import { arrayNotEmpty } from '../../util/generic';
import { Section, RightSidebar } from './SupportPanel.style';
import CustomerDetails from '../../components/CustomerDetails/CustomerDetails';
import SearchCustomers from '../../components/SearchCustomers';

const SupportPanel = () => {
  const [searchCustomers, setSearchCustomers] = useState([]);
  const [activeConfiguration, setActiveConfiguration] = useState();

  const handleActivate = (configuration) => {
    setActiveConfiguration(configuration);
  };

  return (
    <>
      <Sidebar>
        <SearchCustomers setSearchCustomers={setSearchCustomers} />
      </Sidebar>
      <Main>
        {arrayNotEmpty(searchCustomers) && (
          searchCustomers.map((searchCustomer, index) => (
            <Section>
              <CustomerDetails key={`searchCustomer-${index}`} customer={searchCustomer} handleActivate={handleActivate} />
            </Section>
          ))
        )}
      </Main>
      <RightSidebar>
        {activeConfiguration
          && `${JSON.stringify(activeConfiguration)}`}
      </RightSidebar>
    </>
  );
};

export default SupportPanel;
