import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import axios from 'axios';

import { Message } from '@zendeskgarden/react-forms';
import { Anchor, Button } from '@zendeskgarden/react-buttons';
import { Grid, Col } from '@zendeskgarden/react-grid';
import { Dropdown, Menu, Item, Field, Select } from '@zendeskgarden/react-dropdowns';
import { ReactComponent as Lock } from '@zendeskgarden/svg-icons/src/12/lock-locked-fill.svg';

import { useContextState } from '../../ContextProvider';
import { objectNotEmpty, compareString } from '../../util/generic';
import { LoadingButton, LoadingSpinner } from '../../atoms';
import { Heading2, Heading4, TagButton } from '../../styles/core';
import { StyledRow, LockWrapper } from './CustomerDetails.style';

const LockIcon = (props) => (
  <LockWrapper {...props}>
    <Lock />
  </LockWrapper>
);

const CustomerDetails = ({ customer, customer: { id: customerId, name, email, tier, metadata, metadata: { 'Installation ID': installationId }, sources, subscription = {} }, handleActivate }) => {
  const [{ datastore }] = useContextState();
  const { id: subscriptionId, created, agents } = subscription;

  const [availableTiers, setAvailableTiers] = useState();
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState('');
  const [configurationData, setConfigurationData] = useState();
  const [selectedTier, setSelectedTier] = useState(tier);

  useEffect(() => {
    const fetchTiers = async () => {
      const result = await axios(
        `${process.env.REACT_APP_API_ENDPOINT}/tiers`,
      );
      setAvailableTiers(result.data);
    };
    fetchTiers();
  }, [customer]);

  useEffect(() => {
    const fetchConfigurationData = async () => {
      if (installationId) {
        const installationDetails = await datastore
          .collection('app-config')
          .doc('omni-channel-support-plugin')
          .collection(installationId)
          .get();

        const configurationObject = {};
        installationDetails.forEach((installationConfiguration) => {
          configurationObject[installationConfiguration.id] = installationConfiguration.data();
        });
        setConfigurationData(configurationObject);
      }
    };
    fetchConfigurationData();
  }, [installationId, datastore]);

  const handleSave = async (planId) => {
    if (planId) {
      setMessage('');
      setSaving(true);
      try {
        await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/subscription`, {
          subscriptionId,
          planId,
        });
        setMessage('Tier has been updated!');
      } catch (err) {
        console.error('An error occurred updating tier', err);
        setMessage('An error occurred updating tier');
      }
      setSaving(false);
    }
  };

  return (!selectedTier || !availableTiers)
    ? (<LoadingSpinner />)
    : (
      <Grid>
        <StyledRow>
          <Col>
            <Heading2>
              Installation ID:
              {installationId}
            </Heading2>
          </Col>
        </StyledRow>
        <StyledRow>
          <Col size={4}>
            Name:
          </Col>
          <Col>
            {name}
          </Col>
        </StyledRow>
        <StyledRow>
          <Col size={4}>
            Email:
          </Col>
          <Col>
            {email}
          </Col>
        </StyledRow>
        <StyledRow>
          <Col size={4}>
            Customer ID:
          </Col>
          <Col>
            <Anchor href={`https://dashboard.stripe.com/customers/${customerId}`} target="_blank" external>
              {customerId}
            </Anchor>
          </Col>
        </StyledRow>
        <StyledRow>
          <Col size={4}>
            Subscribed:
          </Col>
          <Col>
            {moment.unix(created).format('M/D/YY h:mma')}
          </Col>
        </StyledRow>
        <StyledRow>
          <Col size={4}>
            # Agents:
          </Col>
          <Col>
            {agents}
          </Col>
        </StyledRow>
        {sources.length > 0
          && (
            <StyledRow>
              <Col size={4}>
                Payment Source:
              </Col>
              <Col>
                {sources.map(({ brand, last4 }, index) => (
                  <div key={`card-${index}`}>
                    {brand}
                    {`(${last4})`}
                  </div>
                ))}
              </Col>
            </StyledRow>
          )}
        <StyledRow>
          <Col size={4}>
            Tier:
          </Col>
          <Col>
            {sources.length < 1
              ? (selectedTier.name)
              : (
                <Dropdown
                  selectedItem={selectedTier.id}
                  onSelect={(selectedId) => {
                    setSelectedTier(availableTiers.find(({ id: availableTierId }) => availableTierId === selectedId));
                  }}
                >
                  <Field>
                    <Select>{selectedTier.name}</Select>
                  </Field>
                  <Menu>
                    {availableTiers.map(({ id: availableTierId, name: availableTierName }) => (
                      <Item key={`tier-${availableTierId}`} value={availableTierId}>
                        {availableTierName}
                      </Item>
                    ))}
                  </Menu>
                </Dropdown>
              )}
          </Col>
        </StyledRow>
        {objectNotEmpty(metadata) && (
          <>
            <StyledRow>
              <Col>
                <Heading4>Metadata</Heading4>
              </Col>
            </StyledRow>
            {Object.entries(metadata).map(([metadataKey, metadataValue], index) => (
              <StyledRow key={`metadata-${index}`}>
                <Col size={4}>
                  {metadataKey}
                </Col>
                <Col>
                  {metadataValue}
                </Col>
              </StyledRow>
            ))}
          </>
        )}
        {objectNotEmpty(configurationData) && (
          <>
            <StyledRow>
              <Col>
                <Heading4>Configuration Details</Heading4>
              </Col>
            </StyledRow>
            {Object.entries(configurationData).map(([providerType, providerData], providerIndex) => (
              <StyledRow key={`provider-${providerIndex}`}>
                <Col size={4}>
                  {providerType}
                </Col>
                <Col>
                  {Object.entries(providerData)
                    .sort(([providerDataKey1], [providerDataKey2]) => (compareString(providerDataKey1, providerDataKey2)))
                    .map(([providerDataKey, providerDataValue], index) => providerDataKey !== 'audit' && (
                      <div key={`providerData-${index}`}>
                        <span>
                          {providerDataKey}
                          :
                        </span>
                        <span title="Decrypt Coming Soon!">
                          {providerDataKey === 'config'
                            ? (
                              <TagButton pill size="small" onClick={() => console.log('providerDataValue', providerDataValue)}>
                                Encrypted
                                <LockIcon />
                              </TagButton>
                            ) : providerDataValue}
                        </span>
                      </div>
                    ))}
                </Col>
              </StyledRow>
            ))}
          </>
        )}
        <StyledRow>
          <Col align="right">
            {sources.length > 0
              && (
                <>
                  <LoadingButton onClick={() => handleSave(selectedTier.id)} loading={saving}>
                    Save
                  </LoadingButton>
                  <Message>{message}</Message>
                </>
              )}
            {handleActivate
              && (
                <Button primary onClick={() => handleActivate(configurationData)}>
                  Activate
                </Button>
              )}
          </Col>
        </StyledRow>
      </Grid>
    );
};

export default CustomerDetails;
