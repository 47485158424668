import React from 'react';
import { Toggle, Label } from '@zendeskgarden/react-forms';

import { useContextState } from '../../ContextProvider';
import { DocumentField } from './DocumentFields.style';

const DocumentFields = (({ collection, collectionPath, documentId }) => {
  const document = collection[documentId];
  const [{ updateActiveCollection }] = useContextState();

  const handleToggleField = (fieldId, fieldValue) => {
    updateActiveCollection(collectionPath,
      {
        ...collection,
        [documentId]: {
          ...document,
          [fieldId]: fieldValue,
        },
      }
    );
  };

  return Object.keys(document)
    .sort()
    .map((documentField, index) => (
      <DocumentField data-type="document-field" key={`documentField-${index}`}>
        <Toggle
          checked={document[documentField]}
          onChange={(event) => {
            handleToggleField(
              documentField,
              event.target.checked,
            )
          }}>
          <Label>
            {documentField}
          </Label>
        </Toggle>
      </DocumentField>
    ))
  }
);

export default DocumentFields;
