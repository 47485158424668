import React, { useState } from 'react';
import { Row as GridRow } from '@zendeskgarden/react-grid';

import DocumentFields from '../DocumentFields';
import { Heading2 } from '../../styles/core';
import {
  AlternatingGridRow,
  DocumentGrid,
  EditDocumentButton,
  DocumentHeader,
  FieldsCol
} from './CollectionDocuments.style';
import FeatureFlagsModal from '../FeatureFlagsModal';
import { arrayEmpty } from '../../util/generic';

const CollectionDocuments = ({ collection, collectionPath, striped = true }) => {
  const [selectedDocumentId, setSelectedDocumentId] = useState();
  const documents = collection && Object.entries(collection)
    .filter(([documentId]) => documentId !== 'PLACEHOLDER')
    .filter(([, document]) => Object.values(document).some((x)=> typeof x === 'boolean'))
    .sort()

  return !collection ? null : (
    <>
      {selectedDocumentId && <FeatureFlagsModal closeModal={() => setSelectedDocumentId(null)} collection={collection} collectionPath={collectionPath} documentId={selectedDocumentId} />}
      {arrayEmpty(documents)
        ? (
          <AlternatingGridRow striped={striped}>
            <DocumentGrid data-type="document">
              <GridRow>
                <DocumentHeader>
                  {/* <EditDocumentButton pill primary size="small" onClick={() => setSelectedDocumentId(documentId)}>Edit Flags</EditDocumentButton> */}
                  <Heading2>No flags in collection</Heading2>
                </DocumentHeader>
              </GridRow>
              <GridRow>
                <div>Children may still have configurable flags.</div>
              </GridRow>
            </DocumentGrid>
          </AlternatingGridRow>
        ) : documents
          .map(([documentId], index) => (
            <AlternatingGridRow key={`document-${index}`} striped={striped}>
              <DocumentGrid data-type="document">
                <GridRow>
                  <DocumentHeader>
                    <EditDocumentButton pill primary size="small" onClick={() => setSelectedDocumentId(documentId)}>Edit Flags</EditDocumentButton>
                    <Heading2>{documentId}</Heading2>
                  </DocumentHeader>
                </GridRow>
                <GridRow data-type="fields">
                  <FieldsCol>
                    <DocumentFields collection={collection} collectionPath={collectionPath} documentId={documentId} />
                  </FieldsCol>
                </GridRow>
              </DocumentGrid>
            </AlternatingGridRow>
          )
        )
      }
    </>
  )
}

export default CollectionDocuments;
