import React from 'react';

import { Modal, Body, Close } from '@zendeskgarden/react-modals';
import CustomerDetails from '../CustomerDetails';

const CustomerModel = ({ customer, onClose }) => (
  <Modal onClose={onClose}>
    <Body>
      <CustomerDetails customer={customer} />
    </Body>
    <Close aria-label="Close modal" />
  </Modal>
);

export default CustomerModel;
