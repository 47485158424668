import styled from 'styled-components';

import { zdSpacingXs, zdColorWhite, zdColorBlue100 } from '@zendeskgarden/css-variables';
import { Row } from '@zendeskgarden/react-tables';

export const CollectionRow = styled(Row)`
  cursor: pointer;
  padding: ${zdSpacingXs};
  ${({ expanded, level }) => expanded && level === 2 && `background-color: ${zdColorBlue100};` }
`;

export const SubCollections = styled.div`
  background-color: ${zdColorWhite};
`;
