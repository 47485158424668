import React, { useEffect, useState } from 'react';
import equal from 'fast-deep-equal';

import { Modal, Body, Close, Header } from '@zendeskgarden/react-modals';
import { Checkbox, Field, Label } from '@zendeskgarden/react-forms';
import { Button } from '@zendeskgarden/react-buttons';

import { useContextState } from '../../ContextProvider';
import { LoadingButton } from '../../atoms';
import { Heading2 } from '../../styles/core';
import { Footer, FooterItem } from './FeatureFlagsModal.style'

import { flags } from '../../data/flags';

const FeatureFlagsModal = ({ closeModal, collectionPath, collection, documentId }) => {
  const initialFlags = collection[documentId]
  const [{ saveCollection }] = useContextState();
  const [updatedFlags, setUpdatedFlags] = useState(initialFlags);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setUpdatedFlags(collection[documentId])
  }, [collection, documentId])

  const handleToggleFlag = (flag) => {
    let { [flag]: existingFlag, ...newFlags } = updatedFlags
    if (updatedFlags[flag] === undefined) {
      const flagValue = (flag in initialFlags) ? initialFlags[flag] : true;
      newFlags = {
        ...newFlags,
        [flag]: flagValue,
      };
    }
    setUpdatedFlags(newFlags);
  };

  const handleSaveDocument = async (collectionPath, documentId, updatedFlags) => {
    if (updatedFlags) {
      setSaving(true);
      try {
        await saveCollection(collectionPath, {
          [documentId]: updatedFlags
        })
        setSaving(false);
        closeModal();
      } catch (err) {
        console.error('An error occurred saving document to firebase', err);
        setSaving(false);
      }
    }
  };

  const availableFlags = !initialFlags ? [] : [...new Set([...Object.values(flags), ...Object.keys(initialFlags)])] // removes dupes

  return (
    <Modal onClose={closeModal}>
      <Header>
        <Heading2>Edit Flags: {collectionPath.split('/').pop().toLocaleUpperCase()}</Heading2>
      </Header>
      <Body>
        {updatedFlags && availableFlags
          .sort()
          .map((flag, index) => {
            return (
              <Field key={`FlagField-${index}`}>
                <Checkbox value={flag} checked={flag in updatedFlags} onChange={(event) => handleToggleFlag(event.target.value)}>
                  <Label>{flag}</Label>
                </Checkbox>
              </Field>
            );
          }
        )}
      </Body>
      <Footer>
        <FooterItem>
          <Button size="small" disabled={equal(initialFlags, updatedFlags)} onClick={() => closeModal()}>
            Cancel
          </Button>
        </FooterItem>
        <FooterItem>
          <LoadingButton primary size="small" loading={saving} disabled={equal(initialFlags, updatedFlags)} onClick={() => handleSaveDocument(collectionPath, documentId, updatedFlags)}>
            Save
          </LoadingButton>
        </FooterItem>
      </Footer>
      <Close />
    </Modal>
  );
};

export default FeatureFlagsModal;
