import styled from 'styled-components';

import { zdSpacing, zdSpacingSm, zdSpacingXs } from '@zendeskgarden/css-variables';
import { Row as GridRow, Col as GridCol, Grid } from '@zendeskgarden/react-grid';
import { Button } from '@zendeskgarden/react-buttons';

export const DocumentHeader = styled.div`
  display: flex;
`

export const FieldsCol = styled(GridCol)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${zdSpacingXs};
`;

export const AlternatingGridRow = styled(GridRow)`
  &:nth-child(odd) {
    background: ${({ theme, striped }) => striped ? theme.colors.tertiary : 'initial' }
  };
`;

export const DocumentGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  padding: ${zdSpacingSm} ${zdSpacing} ${zdSpacingXs}!important;
`;

export const EditDocumentButton = styled(Button)`
  min-width: unset!important;
  padding-left: 10px!important;
  padding-right: 10px!important;
  margin-right: ${zdSpacingSm}!important;
  line-height: ${zdSpacing}!important;
`;
