import styled from 'styled-components';
import { Content } from '@zendeskgarden/react-chrome';
import { zdColorWhite, zdSpacingXs } from '@zendeskgarden/css-variables';

// eslint-disable-next-line import/prefer-default-export
export const SpinnerWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const WrappedContent = styled(Content)`
  flex-wrap: wrap;
`;

export const TooltipWrapper = styled.div`
  background: ${zdColorWhite};
  padding: ${zdSpacingXs};
  min-width: 200px;
`

export const TooltipSeries = styled.div`
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  padding: 0 ${zdSpacingXs};
`

export const TooltipTier = styled.span`
  font-weight: bold;
  color: ${({ seriesColor }) => seriesColor};
`