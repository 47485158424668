import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Row, Col } from '@zendeskgarden/react-grid';
import { KPIMetric, StyledGrid } from './KPIs.style';
import { formatNumber } from '../../util/generic';
import TIER_PRICES from '../../data/tierPrices.json'

const KPIs = ({ data }) => {
  const [monthlyAgentsByTier, setMonthlyAgentsByTier] = useState();
  
  useEffect(() => {
    const getAgentCount = timeStampAndAgent => timeStampAndAgent[Object.keys(timeStampAndAgent)[0]]

    const getMonthlyAgentsByTier = data => {
      return Object.keys(data).reduce((tiers, tier) => {
        return {
          ...tiers,
          [tier]: getAgentCount(data[tier][0]) // GET COUNT FOR LAST DAY IN DATA
        }
      }, {})
    }
    
    const fetchData = async () => {
      const { data } = await axios(`${process.env.REACT_APP_API_ENDPOINT}/agents`)
      const monthlyAgentsByTier = getMonthlyAgentsByTier(data)
      setMonthlyAgentsByTier(monthlyAgentsByTier);
    };

    fetchData();
  }, []);

  const calculateMonthlyAgentsByTier = agentsByTier => {
    return Object.keys(agentsByTier).reduce((agents, tier) => {
      return agents + agentsByTier[tier];
    }, 0)
  }

  const calculateMonthlyPaidAgentsPercentage = agentsByTier => {
    const monthlyAgentsByTier = calculateMonthlyAgentsByTier(agentsByTier);
    return !monthlyAgentsByTier ? 0 : Math.round((calculateMonthlyPaidAgentByTier(agentsByTier)*100/monthlyAgentsByTier) * 10)/10
  }

  const calculateMonthlyPaidAgentByTier = agentsByTier => {
    return Object.keys(agentsByTier).reduce((agents, tier) => (
      !tier.startsWith('free') ? agents + agentsByTier[tier] : agents
    ), 0)
  }

  const calculateMonthlyRecurringRevenue = agentsByTier => {
    return formatNumber(Object.keys(agentsByTier).reduce((monthlyRevenue, tier) => {
      return monthlyRevenue + agentsByTier[tier] * TIER_PRICES[tier];
    }, 0))
  }

  const customerCount = formatNumber(data.length);

  return (
    <StyledGrid>
      { monthlyAgentsByTier && (
        <Row>
          <Col align="center">
            <KPIMetric>{customerCount}</KPIMetric>
            <div>Monthly # of Customers</div>
          </Col>
          <Col align="center">
            <KPIMetric>{formatNumber(calculateMonthlyAgentsByTier(monthlyAgentsByTier))}</KPIMetric>
            <div>Monthly # of Active Agents</div>
          </Col>
          <Col align="center">
            <KPIMetric>{calculateMonthlyPaidAgentsPercentage(monthlyAgentsByTier)}%</KPIMetric>
            <div>% of Paid Agents</div>
          </Col>
          <Col align="center">
            <KPIMetric>{formatNumber(calculateMonthlyPaidAgentByTier(monthlyAgentsByTier))}</KPIMetric>
            <div>Monthly # Paid Agents</div>
          </Col>
          <Col align="center">
            <KPIMetric>
              ${calculateMonthlyRecurringRevenue(monthlyAgentsByTier)}
            </KPIMetric>
            <div>MRR</div>
          </Col>
        </Row>
      )}
    </StyledGrid>
  );
};

export default KPIs;
