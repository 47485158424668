import React, { useState, useEffect } from 'react';
import axios from 'axios';
import equal from 'fast-deep-equal';
import { FooterItem } from '@zendeskgarden/react-chrome';
import { Col as GridCol, Row as GridRow } from '@zendeskgarden/react-grid';
import {
  Table,
  Head,
  HeaderRow,
  HeaderCell,
  Body
} from '@zendeskgarden/react-tables';

import { useContextState } from '../../ContextProvider';
import Collections from '../../components/Collections';
import CollectionDocuments from '../../components/CollectionDocuments';
import { LoadingSpinner, LoadingButton } from '../../atoms';
import { Heading1 } from '../../styles/core';
import {
  Main,
  Sidebar,
  Footer,
  MainGrid,
} from './FeatureFlagsPanel.style';
import { objectNotEmpty } from '../../util/generic';

const FeatureFlagsPanel = () => {
  const [initialData, setInitialData] = useState();
  const [{ collectionPath, collection, updatedCollection, saving = false }, setContextState] = useContextState();

  const setSaving = (isSaving) => setContextState({ saving: isSaving })
  const selectCollection = (collectionPath, collection) => {
    setContextState({
      collectionPath,
      collection,
      updatedCollection: collection
    })
  }

  const updateActiveCollection = (updatingCollectionPath, updatedCollection) => {
    if (!updatingCollectionPath === collectionPath) {
      selectCollection(updatingCollectionPath, updatedCollection)
    } else {
      setContextState({
        updatedCollection
      })
    }
  }

  const fetchData = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/flags/${process.env.REACT_APP_FB_FEATURE_FLAGS_ROOT}`)
    
    if (objectNotEmpty(data)) {
      setInitialData(data);

      if (!collection) {
        const [collectionName, collection] = Object.entries(data)[0]
        selectCollection(`/${collectionName}`, collection)
      }
    }
  };

  const saveCollection = async (collectionPath, data) => {
    if (collectionPath) {
      setSaving(true);
      try{
        await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/flags/${process.env.REACT_APP_FB_FEATURE_FLAGS_ROOT}`, {
          path: collectionPath,
          data,
        });
        await fetchData();
        selectCollection(collectionPath, data)
      } catch (error) {
        console.error('error', error)
      }
      setSaving(false);
    } else {
      console.warn('Missing collectionPath')
    }
  };
  
  useEffect(() => {
    fetchData();
    setContextState({
      fetchData,
      selectCollection,
      saveCollection,
      updateActiveCollection
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return !initialData || !updatedCollection
    ? (<LoadingSpinner />)
    : (
    <>
      <Sidebar>
        <Table>
          <Head>
            <HeaderRow>
              <HeaderCell>Featured Flags</HeaderCell>
            </HeaderRow>
          </Head>
          <Body>
            <Collections collections={initialData} />
          </Body>
        </Table>
      </Sidebar>
      <Main>
        {collectionPath && (
          <>
            <Heading1>{collectionPath.split('/').pop().toLocaleUpperCase()}</Heading1>
            <MainGrid>
              <GridRow>
                <GridCol>
                  <CollectionDocuments collection={updatedCollection} collectionPath={collectionPath} />
                </GridCol>
              </GridRow>
            </MainGrid>
          </>
        )}
      </Main>
      <Footer>
        {/* <FooterItem>
          <Button basic disabled={!isModified}>Cancel</Button>
        </FooterItem> */}
        <FooterItem>
          <LoadingButton primary loading={saving} disabled={equal(collection, updatedCollection)} onClick={() => saveCollection(collectionPath, updatedCollection)}>
            Save
          </LoadingButton>
        </FooterItem>
      </Footer>
    </>
  );
};

export default FeatureFlagsPanel;
