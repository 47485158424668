import React, { useContext } from 'react';

import GlobalStyle from './styles/global';

const GlobalState = React.createContext();
const GlobalDispatch = React.createContext();

export const useContextState = () => {
  const contextState = useContext(GlobalState);
  const dispatch = useContext(GlobalDispatch);

  const setContextState = (payload) => {
    dispatch({ type: 'SET', payload });
  };

  return [contextState, setContextState, dispatch];
};

// eslint-disable-next-line consistent-return
const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'SET':
      if (!payload) {
        break;
      }
      return { ...state, ...payload };
    default:
      throw new Error(`action.type[${type}] not mapped`);
  }
};

const ContextProvider = ({ children, initialState = {} }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <>
      <GlobalStyle />
      <GlobalState.Provider value={state}>
        <GlobalDispatch.Provider value={dispatch}>
          {children}
        </GlobalDispatch.Provider>
      </GlobalState.Provider>
    </>
  );
};

export default ContextProvider;
