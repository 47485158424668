import React from 'react';
import moment from 'moment-timezone';
import {
  Head,
  HeaderRow,
  HeaderCell,
  Caption,
  Body,
  Row,
  Cell,
} from '@zendeskgarden/react-tables';

import { compareDate } from '../../util/generic';
import { Heading2, ScrollableTable } from '../../styles/core';

const RecentSubscriptions = ({ data, onClick, rows = 8 }) => (
  <ScrollableTable rows={rows}>
    <Caption>
      <Heading2 align="center">Recent Subscriptions</Heading2>
    </Caption>
    <Head>
      <HeaderRow>
        <HeaderCell width="40%">Subscribed Date</HeaderCell>
        <HeaderCell width="40%">SubDomain</HeaderCell>
        <HeaderCell>Tier</HeaderCell>
      </HeaderRow>
    </Head>
    <Body>
      {data.sort(({ subscription: { created: created1 } }, { subscription: { created: created2 } }) => (compareDate(moment.unix(created1), moment.unix(created2))))
        .slice(0, 20)
        .map(({ id, metadata: { Host: subdomain }, subscription: { created }, tier: { name: tierName } }, index) => (
          <Row key={`customer-${index}`} striped={index % 2 === 0} onClick={() => onClick({ id })}>
            <Cell width="40%">{moment.unix(created).format('M/D/YYYY h:mma')}</Cell>
            <Cell width="40%" truncate>{subdomain}</Cell>
            <Cell>{tierName.substring(0, tierName.indexOf('_')).toUpperCase()}</Cell>
          </Row>
        ))}
    </Body>
  </ScrollableTable>
);

export default RecentSubscriptions;
