import React from 'react';

import { Location, navigate } from '@reach/router';
import { Nav, NavItem, NavItemIcon, NavItemText } from '@zendeskgarden/react-chrome';
import { ReactComponent as LogoutIcon } from '@zendeskgarden/svg-icons/src/16/exit-fill.svg';
import { ReactComponent as DashboardIcon } from '@zendeskgarden/svg-icons/src/26/dashboard.svg';
import { ReactComponent as SupportIcon } from '@zendeskgarden/svg-icons/src/26/platform.svg';
import { ReactComponent as AdminIcon } from '@zendeskgarden/svg-icons/src/26/arrange-content.svg';

import { useContextState } from '../../ContextProvider';
import { ReactComponent as AgnoStackIcon } from './agnoStack-icon-inverse.svg';
import { StyledNavItem } from './Navigation.style';

const Navigation = () => {
  const [{ logout }] = useContextState();

  return (
    <Location>
      {({ location: { pathname } }) => (
        <Nav>
          <NavItem logo title="© agnoSTACK">
            <NavItemIcon>
              <AgnoStackIcon />
            </NavItemIcon>
            <NavItemText>© agnoSTACK</NavItemText>
          </NavItem>
          {pathname !== '/' && (
            <>
              <NavItem title="Dashboard" current={pathname === '/dashboard'} onClick={() => navigate('/dashboard')}>
                <NavItemIcon>
                  <DashboardIcon />
                </NavItemIcon>
                <NavItemText>Dashboard</NavItemText>
              </NavItem>
              <NavItem title="Support" current={pathname === '/support'} onClick={() => navigate('/support')}>
                <NavItemIcon>
                  <SupportIcon />
                </NavItemIcon>
                <NavItemText>Support</NavItemText>
              </NavItem>
              <NavItem title="Admin" current={pathname === '/admin'} onClick={() => navigate('/admin')}>
                <NavItemIcon>
                  <AdminIcon />
                </NavItemIcon>
                <NavItemText>Admin</NavItemText>
              </NavItem>
              <StyledNavItem title="Logout" brandmark onClick={() => logout()}>
                <NavItemIcon>
                  <LogoutIcon />
                </NavItemIcon>
                <NavItemText>Logout</NavItemText>
              </StyledNavItem>
            </>
          )}
        </Nav>
      )}
    </Location>
  );
};

export default Navigation;
