import styled from 'styled-components';

import { zdSpacingXxs } from '@zendeskgarden/css-variables';
import { LoadingButton } from '../../atoms';

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SearchButton = styled(LoadingButton)`
  margin-top: ${zdSpacingXxs}!important;
`;
