import styled from 'styled-components';

import { zdSpacingXl } from '@zendeskgarden/css-variables';
import { Table } from '@zendeskgarden/react-tables';
import { Tag } from '@zendeskgarden/react-tags';

export const ScrollableTable = styled(Table).attrs(({ rows, scrollable }) => ({
  scrollable: rows ? `${Number(zdSpacingXl.replace('px', '')) * rows}px` : scrollable,
}))``;

export const TagButton = styled(Tag)`
  cursor: pointer!important;
  margin: 0 2px;
  padding: 1px 8px 0!important;
  text-transform: uppercase;
  vertical-align: middle;
`;

const headingFont = ({
  theme, tag, size, weight, transform, align, type = 'default',
}) => ` 
  ${weight ? `font-weight: ${weight};` : ''}
  ${transform ? `text-transform: ${transform};` : ''}
  ${theme.typography.heading[type] ? `font-family: ${theme.typography.heading[type]};` : ''}
  font-size: ${
  size
    ? theme.typography.sizes[size]
    : theme.typography.sizes[theme.typography.sizes.headings[tag]]
};
  ${align ? `text-align: ${align};` : ''}
`;

export const Heading1 = styled.h1`
  ${({ theme, size, type, weight, transform, align }) => `
    ${headingFont({ theme, size, type, weight, transform, align, tag: 'h1' })}
  `}
`;

export const Heading2 = styled.h2`
  ${({ theme, size, type, weight, transform, align }) => `
    ${headingFont({ theme, size, type, weight, transform, align, tag: 'h2' })}
  `}
`;

export const Heading3 = styled.h3`
  ${({ theme, size, type, weight, transform, align }) => `
    ${headingFont({ theme, size, type, weight, transform, align, tag: 'h3' })}
  `}
`;

export const Heading4 = styled.h4`
  ${({ theme, size, type, weight, transform, align }) => `
    ${headingFont({ theme, size, type, weight, transform, align, tag: 'h4' })}
  `}
`;

export const Heading5 = styled.h5`
  ${({ theme, size, type, weight, transform, align }) => `
    ${headingFont({ theme, size, type, weight, transform, align, tag: 'h5' })}
  `}
`;

export const Heading6 = styled.h6`
  ${({ theme, size, type, weight, transform, align }) => `
    ${headingFont({ theme, size, type, weight, transform, align, tag: 'h6' })}
  `}
`;
