import React, { useState } from 'react';
import { Table, Cell } from '@zendeskgarden/react-tables';

import { useAccordion } from '@zendeskgarden/container-accordion';

import { useContextState } from '../../ContextProvider';
import { hashCode, arrayNotEmpty } from '../../util/generic';
import { SubCollections, CollectionRow } from './Collections.style';

const hasCollections = (document) => arrayNotEmpty(Object.values(document).filter(child => typeof child === 'object'))

const Collections = ({ collections, parentPath = '/', level = 1, ...renderProps }) => {
  const [{ collectionPath, selectCollection }] = useContextState();
  const selectedCollectionHash = hashCode(collectionPath)

  const [selectedCollectionHashes, setSelectedCollectionHashes] = useState([selectedCollectionHash]);

  const {
    getHeaderProps,
    getTriggerProps,
    getPanelProps,
    expandedSections
  } = useAccordion({
    expandedSections: selectedCollectionHashes,
    expandable: false,
    collapsible: false,
    onChange: setSelectedCollectionHashes
  });

  return Object.keys(collections).map((collectionId, index) => {
    const collectionPath = `${parentPath}${collectionId}`
    const collectionHash = hashCode(collectionPath)
    const hidden = !expandedSections.includes(collectionHash)
    const expanded = expandedSections.includes(collectionHash)
    const selected = collectionHash === selectedCollectionHash
    
    const { onClick: accordionClick, ...triggerProps} = getTriggerProps({
      index: collectionHash,
      tabIndex: '+1',
    })

    return !hasCollections(collections[collectionId]) ? null : (
      <Table key={`collections-${collectionHash}`} data-type="collections" data-level={level} {...renderProps}>
        <CollectionRow
          {...getHeaderProps({
            ariaLevel: level
          })}
          {...triggerProps}
          focused={expanded && level === 1}
          selected={expanded && level === 1}
          expanded={expanded}
          level={level}
          title={collectionId.toLocaleUpperCase()}
          onClick={(event) => {
            if(!selected) {
              accordionClick(event)
              selectCollection(`${parentPath}${collectionId}`, collections[collectionId])  
            }
          }}
        >
          <Cell style={{ paddingLeft: level * 15 }}>
            {collectionId.toLocaleUpperCase()}
          </Cell>
        </CollectionRow>
        <SubCollections data-type="sub-collections" {...getPanelProps({
          index: collectionHash,
          hidden,
          disabled: expanded
        })}>
          <Collections collections={collections[collectionId]} parentPath={`${parentPath}${collectionId}/`} level={level + 1}/>
        </SubCollections>
      </Table>
    );
  });
}

export default Collections;
