import styled from 'styled-components';

import { zdSpacingXxs } from '@zendeskgarden/css-variables';
import { Row } from '@zendeskgarden/react-grid';

export const StyledRow = styled(Row)`
  padding: ${zdSpacingXxs} 0;
`;

export const LockWrapper = styled.span`
  display: inline-flex;
  width: 10px;
  height: 10px;
  padding-bottom: 1px;
`;
