import React from 'react';
import { ThemeProvider as ConfigProvider } from 'styled-components';
import { ThemeProvider } from '@zendeskgarden/react-theming';

import ContextProvider from './ContextProvider';
import Firebase from './util/firebase';
import Layout from './views/Layout';

import '@zendeskgarden/css-bedrock';
import '@zendeskgarden/react-modals/dist/styles.css';
import '@zendeskgarden/react-buttons/dist/styles.css';
import '@zendeskgarden/react-chrome/dist/styles.css';
import '@zendeskgarden/react-grid/dist/styles.css';
import '@zendeskgarden/react-dropdowns/dist/styles.css';
import '@zendeskgarden/react-forms/dist/styles.css';
import '@zendeskgarden/react-tables/dist/styles.css';
import '@zendeskgarden/react-tags/dist/styles.css';

import './styles/fonts.css';
import theme from './theme';

const { config, garden } = theme;

const App = () => {
  const FB_PROJECT_ID = process.env.REACT_APP_FB_PROJECT_ID;
  const FB_PUBLIC_KEY = process.env.REACT_APP_FB_PUBLIC_KEY;

  const firebase = new Firebase({
    apiKey: FB_PUBLIC_KEY,
    projectId: FB_PROJECT_ID,
    authDomain: `${FB_PROJECT_ID}.firebaseapp.com`,
  });

  const initialState = {
    datastore: firebase.getDB(),
    authProvider: firebase,
    activeUser: null,
  };
  return (
    <ConfigProvider theme={config}>
      <ThemeProvider theme={garden}>
        <ContextProvider initialState={initialState}>
          <Layout />
        </ContextProvider>
      </ThemeProvider>
    </ConfigProvider>
  );
};
export default App;
