import styled from 'styled-components';

import { NavItem } from '@zendeskgarden/react-chrome';

// eslint-disable-next-line import/prefer-default-export
export const StyledNavItem = styled(NavItem)`
  &:hover {
    cursor: pointer;
  }
`;
