import React, { useEffect, useState } from 'react';

import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { navigate } from '@reach/router';

import { useContextState } from '../../ContextProvider';
import { Authentication } from '../../atoms';
import { Heading2 } from '../../styles/core';

const LoginPanel = () => {
  const [{ authProvider, activeUser }, setContextState] = useContextState();
  const setActiveUser = (user) => setContextState({ activeUser: user });
  const [showPending, setShowPending] = useState(false);

  useEffect(() => {
    if (activeUser) {
      navigate('/support');
    }
  }, [activeUser]);

  return (
    <Grid alignItems="center">
      <Row>
        <Col align="center">
          <Heading2>Login/Register</Heading2>
        </Col>
      </Row>
      <Row>
        <Col align="center">
          <Authentication
            OAuthProvider={authProvider}
            loginSuccessCallback={(user) => {
              setActiveUser(user);
            }}
            loginFailureCallback={(error) => console.error(error)}
            loadedCallback={() => setShowPending(false)}
            loginPendingCallback={() => setShowPending(true)}
          />
          {showPending && (
            <div id="firebaseui_container">
              <div className="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-sign-in">
                <div className="firebaseui-card-header">
                  <h1 className="firebaseui-title">Pending Approval</h1>
                </div>
                <div className="firebaseui-card-content">
                  <div className="firebaseui-relative-wrapper">
                    <div className="firebaseui-error-wrapper">
                      <p className="firebaseui-error">Your registration is pending further review. You should receive an email once your account has been approved.</p>
                      <br />
                      <p className="firebaseui-error">Please contact support@agnostack.com if you are still having any issues.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Grid>
  );
};

export default LoginPanel;
