import React, { useState } from 'react';
import moment from 'moment-timezone';

import {
  Head,
  HeaderRow,
  HeaderCell,
  SortableCell,
  Caption,
  Body,
  Row,
  Cell,
} from '@zendeskgarden/react-tables';

import { compareNumber, compareDate, compareString, traverse } from '../../util/generic';
import { Heading2, ScrollableTable } from '../../styles/core';

const MaxAgentsByTier = ({ data, onClick, rows = 10 }) => {
  const [sortState, setSortState] = useState({
    sortField: 'subscription.agents',
    sortComparator: compareNumber,
    sortDirection: 'desc'
  });

  const handleSortChange = (sortingField, sortComparator = compareString) => {
    if (sortState.sortField === sortingField) {
      setSortState(
        !sortState.sortDirection || sortState.sortDirection === 'asc'
          ? { ...sortState, ...{ sortDirection: 'desc' } }
          : { ...sortState, ...{ sortDirection: 'asc' } }
      );
    } else {
      setSortState({
        sortField: sortingField,
        sortComparator: sortComparator,
        sortDirection: 'desc'
      });
    }
  };

  return (
    <ScrollableTable rows={rows}>
      <Caption>
        <Heading2 align="center">Max Agent Subscriptions By Tier</Heading2>
      </Caption>
      <Head>          
        <HeaderRow>
          <HeaderCell width="20%">SubDomain</HeaderCell>
          <HeaderCell width="25%">Email</HeaderCell>
          <SortableCell width="10%" sort={
            sortState.sortField === 'tier.name'
              ? sortState.sortDirection
              : null
            }
            onClick={() => handleSortChange('tier.name', compareString)}
          >
            Tier
          </SortableCell>
          <HeaderCell width="15%">Installation ID</HeaderCell>
          <SortableCell width="15%" sort={
            sortState.sortField === 'subscription.created'
              ? sortState.sortDirection
              : null
            }
            onClick={() => handleSortChange('subscription.created', compareDate)}
          >
            Subscribed Date
          </SortableCell>
          <SortableCell width="15%" sort={
            sortState.sortField === 'subscription.agents'
              ? sortState.sortDirection
              : null
            }
            onClick={() => handleSortChange('subscription.agents', compareNumber)}
          >
            Agents
          </SortableCell>
        </HeaderRow>
      </Head>
      <Body>
        {data.sort(({ subscription: { agents: agents1 } }, { subscription: { agents: agents2 } }) => (compareNumber(agents2, agents1)))
          .slice(0, 20)
          .sort((a, b) => {
            const aValue = traverse(sortState.sortField, a), bValue = traverse(sortState.sortField, b);
            const sortComparison = sortState.sortComparator(aValue, bValue);
            return sortState.sortDirection === 'asc' ? sortComparison : -sortComparison;
          })
          .map(({ id, email, subscription: { agents, created }, metadata: { Host: subdomain, 'Installation ID': installation }, tier: { name: tierName } }, index) => (
            <Row key={`customer-${index}`} striped={index % 2 === 0} onClick={() => onClick({ id })}>
              <Cell width="20%" truncate>{subdomain}</Cell>
              <Cell width="25%" truncate>{email}</Cell>
              <Cell width="10%">{tierName}</Cell>
              <Cell width="15%">{installation}</Cell>
              <Cell width="15%">{moment.unix(created).format('M/D/YYYY')}</Cell>
              <Cell>{agents}</Cell>
            </Row>
          ))}
      </Body>
    </ScrollableTable>
  )
};

export default MaxAgentsByTier;
