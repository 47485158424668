import * as firebase from 'firebase';
import * as firebaseui from 'firebaseui';
import 'firebase/auth';

export default class Firebase {
  constructor(firebaseConfig) {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
  }

  getDB() {
    return firebase.firestore();
  }

  getOAuth() {
    return firebase.auth();
  }

  clearOAuthUser() {
    return this.getOAuth().signOut();
  }

  getOAuthUser() {
    return this.getOAuth().currentUser;
  }

  getOAuthUI(
    successCallback = () => {},
    failureCallback = () => {},
    pendingCallback = () => {},
    loadedCallback = () => {},
  ) {
    return {
      // tosUrl and privacyPolicyUrl accept either url string or a callback
      // function.
      // Terms of service url/callback.
      // tosUrl: '<your-tos-url>',
      // Privacy policy url/callback.
      // privacyPolicyUrl: function() {
      //   window.location.assign('<your-privacy-policy-url>');
      // }
      // Popup signin flow rather than 'redirect' flow.
      signInFlow: 'popup',
      // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
      // signInSuccessUrl: '/signedIn',
      callbacks: {
        // eslint-disable-next-line no-unused-vars
        signInSuccessWithAuthResult: ({ user: { uid, displayName, email, phoneNumber, photoURL }, additionalUserInfo: { isNewUser } }, redirectUrl) => {
          // const credential = authResult.credential;
          if (isNewUser) {
            // await this.getOAuth().updateUser(uid, { disabled: true })
            pendingCallback({ uid, displayName, email, phoneNumber, photoURL });
          } else {
            successCallback({ uid, displayName, email, phoneNumber, photoURL });
          }
          // const providerId = authResult.additionalUserInfo.providerId;
          // const operationType = authResult.operationType;
          // console.log(
          //   'getAuthUI.signInSuccessWithAuthResult, authResult',
          //   authResult
          // );
          // Do something with the returned AuthResult.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
          return false;
        },
        signInFailure: (error) => {
          // Some unrecoverable error occurred during sign-in.
          // Return a promise when error handling is completed and FirebaseUI
          // will reset, clearing any UI. This commonly occurs for error code
          // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
          // occurs. Check below for more details on this.
          // return handleUIError(error);
          console.error('getAuthUI.signInFailure, error', error);
          failureCallback(error);
          return false;
        },
        uiShown: () => {
          // The widget is rendered.
          // Hide the loader.
          // document.getElementById('loader').style.display = 'none';
          // console.log('getAuthUI.uiShown');
          loadedCallback();
        },
      },
      signInOptions: [
        // firebase.auth.EmailAuthProvider.PROVIDER_ID,
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          // Whether the display name should be displayed in the Sign Up page.
          requireDisplayName: true,
          allowNewAccounts: false,
        },
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        // firebase.auth.GithubAuthProvider.PROVIDER_ID,
        // firebase.auth.OAuthProvider('microsoft.com'), // DOES NOT WORK!,
        // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID,
        // {
        //   provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        //   // Invisible reCAPTCHA with image challenge and bottom left badge.
        //   recaptchaParameters: {
        //     type: 'image',
        //     size: 'invisible',
        //     badge: 'bottomleft'
        //   }
        // }
      ],
      // credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      // credentialHelper: firebaseui.auth.CredentialHelper.ACCOUNT_CHOOSER_COM,
      credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
    };
  }
}
