import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import { ResponsivePie } from '@nivo/pie';

import { ChartWrapper } from './SubscriptionsByTier.style';
import { Heading2 } from '../../styles/core';

const SubscriptionsByTierChart = ({ theme, data, onClick }) => {
  const totalAgents = data.reduce((count, { value }) => (count + value), 0);
  const [selectedSlice, setSelectedSlice] = useState();

  const colors = {
    FREE: theme.formats.default,
    STARTER: theme.formats.highlight,
    PROFESSIONAL: theme.formats.accent2,
    PROFESIONAL: theme.formats.accent2,
    ENTERPRISE: theme.formats.medium,
  };

  const handleSelectSlice = (slice) => {
    setSelectedSlice(selectedSlice && selectedSlice.id === slice.id ? null : slice);
    return onClick(selectedSlice && selectedSlice.id === slice.id ? null : slice);
  };

  return (
    <ResponsivePie
      data={data}
      onClick={(slice) => handleSelectSlice(slice)}
      colors={(slice) => colors[slice.label].background}
      borderWidth={1.5}
      // eslint-disable-next-line no-confusing-arrow
      borderColor={({ data: slice }) => (selectedSlice && selectedSlice.id === slice.id) ? theme.colors.dark : colors[slice.label].accent}
      enableRadialLabels={false}
      // eslint-disable-next-line no-mixed-operators
      sliceLabel={(slice) => `${slice.label} (${Math.round((slice.value / totalAgents * 100) * 10)/10}%)`}
      slicesLabelsTextColor={(slice) => colors[slice.label].color}
      innerRadius={0.2}
      padAngle={1}
      cornerRadius={10}
      // eslint-disable-next-line react/jsx-boolean-value
      animate={true}
      motionStiffness={90}
      motionDamping={15}
    />
  );
};

const SubscriptionsByTier = ({ theme, data, onClick }) => {
  const tiers = Object.values(data.reduce((count, { tier }) => {
    // eslint-disable-next-line no-param-reassign
    count[tier.name] = count[tier.name] || { tierName: tier.name, customers: 0 };
    // eslint-disable-next-line no-param-reassign, no-plusplus
    count[tier.name].customers++;
    return count;
  }, {}));

  const chartData = tiers.map(({ tierName, customers }) => ({
    id: tierName,
    label: tierName.substring(0, tierName.indexOf('_')).toUpperCase(),
    value: customers,
  }));

  return (
    <>
      <Heading2 align="center">Subscriptions By Tier</Heading2>
      <ChartWrapper>
        <SubscriptionsByTierChart theme={theme} data={chartData} onClick={onClick} />
      </ChartWrapper>
    </>
  );
};

export default withTheme(SubscriptionsByTier);
