import styled from 'styled-components';

import { Grid } from '@zendeskgarden/react-grid';

export const KPIMetric = styled.div`
  font-size: 3em;
  line-height: .9;
`;

export const StyledGrid = styled(Grid)`
  padding: 2vh;
`;
