import moment from 'moment-timezone';

export const ensureObject = (object) => object || {};

export const objectEmpty = (object) => !object || !Object.keys(object).length;

export const objectNotEmpty = (object) => !objectEmpty(object);

export const ensureArray = (array = []) => (Array.isArray(array) ? array : [array]);

export const arrayEmpty = (array) => !array || !array.length;

export const arrayNotEmpty = (array) => !arrayEmpty(array);

export const compareString = (string1, string2) => string1.localeCompare(string2);

export const compareNumber = (number1, number2) => number1 - number2;

export const compareDate = (date1, date2) => -moment.utc(date1).diff(moment.utc(date2));

export const isValidEmail = (email) => /^[a-z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-z0-9-]+(?:.[a-z0-9-]+)+$/i.test(email);

export const formatNumber = (number, locale = 'en') => Number(number).toLocaleString(locale);

export const hashCode = (object) => {
  const key = object ? JSON.stringify(object) : null
  return key
    ? Array.from(key).reduce((s, c) => {
      // eslint-disable-next-line no-bitwise
      return (Math.imul(31, s) + c.charCodeAt(0)) | 0
    }, 0)
    : null
};

// eslint-disable-next-line no-restricted-globals
export const traverse = (selector, obj = self, separator = '.') => {
  const properties = Array.isArray(selector)
    ? selector
    : selector.split(separator);
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
};
