import styled from 'styled-components';

import { zdSpacing, zdSpacingXs } from '@zendeskgarden/css-variables';
import { Footer as _Footer, FooterItem as _FooterItem } from '@zendeskgarden/react-modals';

export const Footer = styled(_Footer)`
  padding: ${zdSpacing}!important;
`;

export const FooterItem = styled(_FooterItem)`
  margin-left: ${zdSpacingXs}!important;
`;
