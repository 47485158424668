import styled from 'styled-components';

import { zdSpacingXxs, zdSpacingXs, zdColorGrey300 } from '@zendeskgarden/css-variables';
import { Sidebar } from '@zendeskgarden/react-chrome';

export const RightSidebar = styled(Sidebar)`
  order: 1!important;
  min-width: 28%;
  border-left: 1px solid ${zdColorGrey300};
`;

export const Section = styled.section`
  padding-bottom: ${zdSpacingXxs};
  margin-bottom: ${zdSpacingXs};

  &:nth-child(n+2) {
    padding-top: ${zdSpacingXxs};
  }

  &:nth-child(even) {
    background-color: ${zdColorGrey300};
  }
`;
