import styled from 'styled-components';

import { zdSpacing, zdSpacingSm, zdSpacingXs, zdSpacingXxs } from '@zendeskgarden/css-variables';
import { Row as GridRow, Col as GridCol, Grid } from '@zendeskgarden/react-grid';
import { Sidebar as _Sidebar, Main as _Main, Footer as _Footer } from '@zendeskgarden/react-chrome';
import { Field as _Field } from '@zendeskgarden/react-forms';
import { Button } from '@zendeskgarden/react-buttons';
import { LoadingButton } from '../../atoms';

const FOOTER_HEIGHT = '60px';

export const MainGrid = styled(Grid)`
  margin-top: ${zdSpacingXxs};
`

export const DocumentHeader = styled.div`
  display: flex;
`

export const FieldsCol = styled(GridCol)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${zdSpacingXs};
`;

export const Field = styled(_Field)`
  padding: ${zdSpacingXs} 0!important;
  flex-basis: 50%;
`;

export const AlternatingGridRow = styled(GridRow)`
  &:nth-child(odd) {
    background: ${({ theme }) => theme.colors.tertiary}
  };
`;

export const Document = styled(Grid)`
  display: flex;
  flex-direction: column;
  padding: ${zdSpacing}!important;
`;

export const EditDocumentButton = styled(Button)`
  min-width: unset!important;
  padding-left: 10px!important;
  padding-right: 10px!important;
  margin-right: ${zdSpacingSm}!important;
  line-height: ${zdSpacing}!important;
`;

export const SaveCollectionButton = styled(LoadingButton).attrs(() => ({
  primary: true,
  pill: true
}))``;

export const Sidebar = styled(_Sidebar)`
  height: 100%
`;

export const Main = styled(_Main)`
  height: calc(100% - ${FOOTER_HEIGHT});
`;

export const Footer = styled(_Footer)`
  order: 1;
  flex-basis: calc(100% - 330px);
  transform: translateX(330px) translateY(-${FOOTER_HEIGHT});
  max-height: ${FOOTER_HEIGHT};
`;
