import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment'

import { compareNumber } from '../../util/generic';
import { AreaChart } from '../../atoms';
import { ChartWrapper } from './PaidSubsByDate.style';
import { Heading2 } from '../../styles/core';

const getChartMinimumAgentsAndData = data => {
  const formattedData = Object.entries(data).map(([tiername, tier]) => {
    const formattedTierData = Object.values(tier).reduce((tierEntry, tierAgentsByDate) => {
      let { tierMinimumAgents, tierData } = tierEntry
      const [timeStamp, agentCount] = Object.entries(tierAgentsByDate)[0]
      if (!tierMinimumAgents || agentCount < tierMinimumAgents) tierMinimumAgents = agentCount
      return {
        tierMinimumAgents,
        tierData: [
          ...tierData,
          { 
            x: moment.unix(timeStamp).format('YYYY-MM-DD'),
            y: agentCount
          }
        ]
      }
    }, { tierData: [] })

    return {
      id: tiername,
      minimumAgents: formattedTierData.tierMinimumAgents,
      data: formattedTierData.tierData
    }
  })
  const minimumAgents = Object.values(formattedData)
    .sort(({ minimumAgents: minimumAgents1, minimumAgents: minimumAgents2 }) => compareNumber(minimumAgents1, minimumAgents2))[0]
    .minimumAgents;

  return {
    minimumAgents,
    formattedData
  }
}

const PaidSubsByDate = () => {
  const [chartData, setChartData] = useState();
  const [chartMinimumAgents, setChartMinimumAgents] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const { data: fetchedData } = await axios(
        `${process.env.REACT_APP_API_ENDPOINT}/agents`,
      );

      if (fetchedData) {
        const { minimumAgents, formattedData } = await getChartMinimumAgentsAndData(fetchedData);
        setChartData(formattedData);
        setChartMinimumAgents(minimumAgents);
      }
    };
    fetchData();
  }, []);

  return !chartData || chartMinimumAgents === null ? null : (
    <>
      <Heading2 align="center">Paid Subscriptions By Date</Heading2>
      <ChartWrapper>
        <AreaChart data={chartData} min={chartMinimumAgents} />
      </ChartWrapper>
    </>
  );
};

export default PaidSubsByDate;