import React, { useEffect } from 'react';
import { Router, navigate, globalHistory } from '@reach/router';
import {
  Chrome,
  Body,
} from '@zendeskgarden/react-chrome';

import { useContextState } from '../ContextProvider';
import DashboardPanel from './DashboardPanel';
import SupportPanel from './SupportPanel';
import LoginPanel from './LoginPanel';
import FeatureFlagsPanel from './FeatureFlagsPanel';
import Navigation from '../components/Navigation';
import { WrappedContent } from '../atoms';

const isRootPath = path => path.match(/(^\/(?!.*\/)|^((?!\/).)*$)/g)

const Layout = () => {
  const [{ logout, activeUser }, setContextState] = useContextState();

  useEffect(() => {
    if (!activeUser && process.env.NODE_ENV !== 'development') {
      navigate('/');
    }
  }, [activeUser]);

  useEffect(() => {
    if (setContextState && !logout) {
      setContextState({
        logout: () => {
          navigate('/')
          setContextState({ activeUser: null })
        }
      });
    }
  }, [logout, setContextState]);

  useEffect(() => {
    if(setContextState) {
      globalHistory.listen(({ action, location: { pathname } }) => {
        if (action === 'PUSH' && isRootPath(pathname)) {
          //NOTE: clear our context on root path switch (ie. dahboard view change)
          setContextState({
            collectionPath: null,
            collection: null,
            updatedCollection: null
          })
        }
      })
    }
  }, [setContextState]);

  return (
    <Chrome>
      <Navigation />
      <Body>
        <Router component={WrappedContent}>
          <LoginPanel default path="/" />
          <DashboardPanel path="/dashboard" />
          <SupportPanel path="/support" />
          <FeatureFlagsPanel path="/admin" />
        </Router>
      </Body>
    </Chrome>
  );
};

export default Layout;
