import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Main } from '@zendeskgarden/react-chrome';

import { useContextState } from '../../ContextProvider';
import { LoadingSpinner } from '../../atoms';

import RecentSubscriptions from '../../components/RecentSubscriptions';
import SubscriptionsByTier from '../../components/SubscriptionsByTier';
import PaySubsByDate from '../../components/PaidSubsByDate';
import MaxAgentsByTier from '../../components/MaxAgentsByTier';
import CustomerModal from '../../components/CustomerModal';
import KPIs from '../../components/KPIs';

const DashboardPanel = () => {
  const [{ datastore }] = useContextState();
  const [selectedTierName, setSelectedTierName] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const { data: fetchedData } = await axios(
        `${process.env.REACT_APP_API_ENDPOINT}/data`,
      );
      setData(fetchedData);
    };
    fetchData();
  }, []);

  const handleFilterData = (selectedTier) => {
    const { id: tierName } = selectedTier || {};
    setSelectedTierName(tierName);
  };

  const handleSelectCustomer = ({ id: customerId }) => {
    const customerData = data.find((customer) => customer.id === customerId);
    setSelectedCustomer(customerData);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedCustomer(null);
    setShowModal(false);
  };

  return !data
    ? (<LoadingSpinner />)
    : (
      <>
        <Main>
          <Grid>
            <Row>
              <Col>
                <KPIs data={data} />
              </Col>
            </Row>
            <Row>
              <Col size={4}><RecentSubscriptions onClick={handleSelectCustomer} data={data} /></Col>
              <Col><PaySubsByDate /></Col>
            </Row>
            <Row>
              <Col size={4}><SubscriptionsByTier onClick={handleFilterData} data={data} /></Col>
              <Col>
                <MaxAgentsByTier
                  data={!selectedTierName ? data : data.filter((customer) => customer.tier.name === selectedTierName)}
                  onClick={handleSelectCustomer}
                />
              </Col>
            </Row>
          </Grid>
        </Main>
        {showModal && <CustomerModal datastore={datastore} customer={selectedCustomer} onClose={handleCloseModal} />}
      </>
    );
};

export default DashboardPanel;
